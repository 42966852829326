<template>
  <v-container fluid class="ma-0 pa-0 fill-height h-error-bg">
    <v-row no-gutters class="h-error-content mt-n16">
      <v-col
        cols="10"
        offset="1"
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        lg="4"
        offset-lg="4"
        xl="4"
        offset-xl="4"
      >
        <v-img src="~@/assets/errors/404-graph.svg"></v-img>
      </v-col>
      <v-col cols="8" offset="2">
        <div class="d-flex flex-column align-center justify-center mt-8">
          <h3 class="white--text mb-2 text-center">
            {{ errorText }}
          </h3>
          <v-btn
            rounded
            color="primary darken-2"
            class="text-capitalize"
            :to="`/`"
            >{{ callToAction }}</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <div class="h-error-cloud"></div>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  data: () => ({
    callToAction: 'bring me home!',
    errorText: 'You may have mistyped the address, or the page may have moved.'
  })
}
</script>

<style scoped lang="scss">
.h-error-bg::v-deep {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba($primary-darken2-rgb, 1) 0%,
    rgba($primary-lighten1-rgb, 1) 82.29%
  );

  & .h-error-content {
    z-index: 5;
  }

  & .h-error-cloud {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 230px;
    background-image: url('../assets/errors/404-cloud.svg');
    z-index: 0;
  }
}
</style>
